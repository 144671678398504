<template>
  <div>
    <van-nav-bar
        :title="user.nickName"
        :border="false"
        left-arrow
        @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="main">
      <div style="display: flex;padding: 10px">
        <img
            v-if="user.headUrl"
            width="60"
            height="60"
            style="border-radius: 50%"
            v-lazyload :data-src="user.headUrl"
        />
        <div class="des">
          <div class="des-name">{{ user.account }}</div>
          <div @click="handleSubscription" style="display: flex;padding-top: 7px">
            <van-icon color="#06f" name="plus" />
            <span  class="author-des">订阅</span>
          </div>
        </div>
        <div class="top-right">
          <div class="top-right-item">
            <p>获赞</p>
            <p class="color-gray">1</p>
          </div>
          <div class="top-right-item">
            <p>被关注</p>
            <p class="color-gray">1</p>
          </div>
          <div class="top-right-item">
            <p>关注</p>
            <p class="color-gray">1</p>
          </div>
        </div>
      </div>
      <van-tabs color="#2f6bdb" v-model:active="active" @change="onClick">
        <van-tab title="创作">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <div style="margin-bottom: 66px">
              <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
              >
                <ArticleList :list="list"/>
              </van-list>
            </div>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="回复">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <div style="margin-bottom: 66px">
              <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
              >
                <div v-for="(item, index) in commentList" :key="index" class="comments-item">
                  <img width="20" height="20" style="border-radius: 10px" v-lazyload :key="item.id" :data-src="item.ext.headUrl" />
                  <div style="margin-left: 15px;flex: 1 1 0%;">
                    <div class="comments-item-d1">{{ item.account }}</div>
                    <div class="comments-item-d2">{{ item.content }}</div>
                    <div class="comments-item-d3">
                      <div class="comments-item-d3-l">
                        {{ item.createTime }}
                      </div>
                      <div class="comments-item-d3-r">
                        <span><van-icon size="16" name="chat-o" />{{item.clickCount}}</span>
                        <span><van-icon size="16" name="like-o" />{{item.zanCount}}</span>
                        <span v-if="!item.isPay" @click.stop="unlock(item)"><van-icon size="16" name="bag-o" />解锁</span>
                      </div>
                    </div>
                  </div>
                </div>
              </van-list>
            </div>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="赞同">内容 2</van-tab>
      </van-tabs>

    </div>
  </div>
</template>

<script>
import {BASE_RUL} from "@/utils/request";
import {getUserInfomation, getUserReplyList, subscriptionUser} from "@/api/user";
import {taskListByCate} from "@/api/article";
import {Dialog, Toast} from "vant";
import ArticleList from "@/components/ArticleList";

export default {
  name: "user",
  components: {ArticleList},
  data() {
    return {
      base: BASE_RUL,
      user:{},
      active:0,
      isLoading: false,
      loading: false,
      finished: false,
      page: 1,
      total: 1,
      list:[],
      commentList:[]
    }
  },
  created() {

  },
  computed: {},
  methods:{
    onClick(e) {
      this.list = []
      this.commentList = []
      this.isLoading = false;
      this.page = 1
      this.total = 0
      if (this.active == 0) {
        this.getTask()
      } else if (this.active == 1) {
        this.getReply()
      } else {
        this.getTaskByCate()
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
    },
   async getInfo(){
     await getUserInfomation({id:this.$route.query.id}).then((res) => {
        if (res.code == 0) {
           this.user=res.result
        }
      })
    },
    getTask(){
      taskListByCate({
        account:this.user.account,
        page:this.page,
        limit:10
      }).then((res) => {
        if (res.code==0) {
          this.list =this.list.concat(res.result.records || [])
          this.total = res.result.total
          this.page += 1
        }
        this.isLoading = false;
        this.loading = false;
        this.finished = true;
        if (this.list.length >= 4) {
          this.finished = true;
        } else {

        }
      })
    },
    getReply(){
      getUserReplyList({
            "queryUserId":this.user.id,
            "page":this.page,
            "limit": "10",
            "sort": "1"
          }).then((res) => {
        if (res.code==0) {
          this.commentList =this.commentList.concat(res.result.records || [])
          this.total = res.result.total
          this.page += 1
        }
        this.isLoading = false;
        this.loading = false;
        this.finished = true;
        if (this.commentList.length >= 4) {
          this.finished = true;
        } else {

        }
      })
    },
   async onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
     await this.getInfo()
      if (this.active == 0) {
        this.getTask()
      } else if (this.active == 1) {
        this.getReply()
      } else {
        this.getTaskByCate()
      }
    },
    onRefresh() {
      this.$toast('刷新成功');
      this.list = []
      this.commentList = []
      this.isLoading = false;
      this.page = 1
      this.total = 0
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if (this.active == 0) {
        this.getTask()
      } else if (this.active == 1) {
        this.getReply()
      } else {
        this.getTaskByCate()
      }
    },
    handleSubscription(){
      Dialog.confirm({
        title: '标题',
        message: '是否订阅该用户?',
      }).then(() => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        subscriptionUser({subUserId:this.info.ext.id}).then((res)=>{
          this.getTaskInfo()
        })
      })
    }
  }
}
</script>

<style scoped>
.main {
  padding: 0 20px 0 20px;
}

.des {
  padding-left: 20px;
  letter-spacing: 1px;
  padding-top: 6px;
}

.des-name {
  font-size: 18px;
  padding-left: 3px;
}

.author-des {
  font-size: 12px;
  color: rgba(0, 0, 0, .6);
  padding-left: 2px;
}

.link-box {
  padding: 0 16px;
}

.link-box .link-row {
  display: flex;
  justify-content: space-between;
}

.link-box .link-row .link-item {
  text-align: center;
  width: 25%;
}

.link-box .link-row .link-item .icon {
  margin-bottom: 0;
}

.link-box .link-row .link-item .link-text {
  margin-top: 10px;
}

.top-right{
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
  padding-left: 10px;
}
.top-right p{
  text-align: center;
  margin: 0;
  margin-bottom:7px;
}
.color-gray{
  color: #ababab;
}
.author-des{
  color: #06f;
}

.comments-item {
  display: flex;
  margin-bottom: 20px;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 20px;
}

.comments-item-d1 {
  color: rgba(0, 0, 0, .9);
}

.comments-item-d2 {
  color: rgba(0, 0, 0, .7);
  padding: 4px 0;
}

.comments-item-d3 {
  color: rgba(0, 0, 0, .4);
  font-size: 11px;
  display: flex;
  justify-content: space-between;
}

.header .avatar {
  border-radius: 17.5px;
}

.task-detail{
  margin-bottom: 10px;
}

.task-detail .task-item {
  display: flex;
  line-height: 30px;
  position: relative;
}

.task-detail .task-item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  content: '';
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-width: 1px 0 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
}

.task-detail .task-item .item-label {
  width: 30%;
  text-align: right;
  padding-right: 5px;
}
.more{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, .4);
}
.comments-item-d3-r span{
  margin-left: 10px;
}
</style>